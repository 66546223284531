<template>
    <div id="footer">
        <a @click="openTermsAndConditionsModal" href="#">Terms & conditions</a>,
        <a @click="openPrivacyPolicyModal" href="#">Privacy Policy</a>,
        <a @click="openCookiePolicyModal" href="#">Cookie Policy</a>,
        SchoolPeak &copy; {{ new Date().getFullYear() }} All rights reserved.
    </div>
    <TermsAndConditionsModal ref="showTermsAndConditionsModal" @open-privacy="openPrivacyPolicyModal"
        @open-cookies="openCookiePolicyModal" />
    <CookiePolicyModal ref="showCookiePolicyModal" @open-privacy="openPrivacyPolicyModal" @open-terms="openTermsAndConditionsModal" />
    <PrivacyPolicyModal ref="showPrivacyPolicyModal" @open-cookies="openCookiePolicyModal" @open-terms="openTermsAndConditionsModal" />
</template>

<script>
import TermsAndConditionsModal from './modals/TermsAndConditionsModal.vue';
import CookiePolicyModal from './modals/CookiePolicyModal.vue';
import PrivacyPolicyModal from './modals/PrivacyPolicyModal.vue';
export default {
  name: 'PageFooter',
    components: { TermsAndConditionsModal, CookiePolicyModal, PrivacyPolicyModal },
  methods: {
    openTermsAndConditionsModal() {
        this.$refs.showCookiePolicyModal.closeModal();
        this.$refs.showPrivacyPolicyModal.closeModal();
        this.$refs.showTermsAndConditionsModal.openModal();
    },
    openCookiePolicyModal() {
        this.$refs.showTermsAndConditionsModal.closeModal();
        this.$refs.showPrivacyPolicyModal.closeModal();
        this.$refs.showCookiePolicyModal.openModal();
    },
    openPrivacyPolicyModal() {
        this.$refs.showTermsAndConditionsModal.closeModal();
        this.$refs.showCookiePolicyModal.closeModal();
        this.$refs.showPrivacyPolicyModal.openModal();
    },
  },
};
</script>

<style scoped>
#footer {
  background-color: #f9f9f9; 
  color: #333;
  padding: 10px;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 30px;
  z-index: 1000;
}
</style>
