export default {
  namespaced: true,
  state: {
    isLoading: false,
    pendingRequests: 0,
    loadingTimeout: null,
  },
  mutations: {
    INCREMENT_REQUESTS(state) {
      state.pendingRequests++;
      if (state.loadingTimeout) {
        clearTimeout(state.loadingTimeout);
      }
      state.loadingTimeout = setTimeout(() => {
        if (state.pendingRequests > 0) {
          state.isLoading = true;
        }
      }, 200);
    },
    DECREMENT_REQUESTS(state) {
      state.pendingRequests--;
      if (state.pendingRequests === 0) {
        if (state.loadingTimeout) {
          clearTimeout(state.loadingTimeout);
        }
        state.isLoading = false;
      }
    },
  },
  actions: {
    startLoading({ commit }) {
      commit('INCREMENT_REQUESTS');
    },
    stopLoading({ commit }) {
      commit('DECREMENT_REQUESTS');
    },
  },
};
