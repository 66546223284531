<template>
    <q-dialog v-model="isOpen">
        <q-card class="terms_and_conditions">
            <q-card-section>
                <h4>Terms for the Use of SchoolPeak Services and Website</h4>

                <h5>1. Introduction</h5>
                <div class="q-pl-md">
                    <p>1.1. These Terms govern your use of the SchoolPeak platform and services (collectively,
                        the "Services"), provided by SIA Peak MGMT, registration number 40203537638
                        ("SchoolPeak," "we," or "us"). By accessing or using the Services, you confirm your
                        acceptance of these Terms.</p>
                    <p>1.2. SchoolPeak operates under the laws of the Republic of Latvia.</p>
                    <p>1.3. For information on how we process your data, please refer to our <a href="#"
                            @click.prevent="$emit('open-privacy')">Privacy Policy</a> and <a href="#" @click.prevent="$emit('open-cookies')">Cookies Policy</a>.</p>
                </div>

                <h5>2. Definitions</h5>
                <div class="q-pl-md">
                    <p>2.1. User: Any authorized individual accessing the SchoolPeak platform.</p>
                    <p>2.2. Parent/Guardian: A User granted access to manage a child's activities, including
                        guardians, grandparents, other relatives, or babysitters authorized by the School.</p>
                    <p>2.3. School Administrator: A User with administrative permissions to manage their
                        institution's workspace.</p>
                    <p>2.4. Teacher: A User responsible for managing educational or after-school activities,
                        either as an internal or external staff member.</p>
                    <p>2.5. Support Staff: Internal or external staff members involved in the operation and
                        support of activities within the SchoolPeak platform.</p>
                    <p>2.6. Portal/Parent Portal/Workspace: A virtual environment (portal) created for a school,
                        with a distinct sub-domain of schoolpeak.org, managed by its School Administrator.</p>
                    <p>2.7. Account: A user account provided by the school to enable access to the platform.</p>
                    <p>2.8. School: A client institution that uses SchoolPeak to manage administrative processes
                        and offer services to its end users—parents, guardians, and support staff.</p>
                </div>

                <h5>3. Creation and Use of an Account</h5>
                <div class="q-pl-md">
                    <p>3.1. Access to SchoolPeak is restricted to pre-registered users authorized by their
                        respective schools. Free registration by unauthorized individuals is not permitted.</p>
                    <p>3.2. Users must maintain the confidentiality of their login credentials and must not
                        share them with others. Password sharing or unauthorized use of credentials is
                        prohibited.</p>
                    <p>3.3. By using the platform, Users agree to comply with these Terms and all applicable
                        laws.</p>
                    <p>3.4. Third-party authentication tools (e.g., Google or Facebook) are not supported for
                        accessing the platform.</p>
                    <p>3.5. For termination of an account, Users must contact their respective school, which
                        will handle the request with SchoolPeak. Termination of the account will restrict/reduce
                        provision of certain services provided by the School.</p>
                </div>

                <h5>4. Responsibilities</h5>
                <div class="q-pl-md">
                    <h6>4.1. User Responsibilities</h6>
                    <div class="q-pl-md">
                        <p>4.1.1. Users must not misuse the Services, engage in illegal activities, or upload
                            prohibited content.</p>
                        <p>4.1.2. Users are responsible for complying with their institution's rules and all
                            relevant legal requirements.</p>
                        <p>4.1.3. Users must not share sensitive or confidential information unless necessary
                            for the use of the Services and authorized by the School.</p>
                    </div>

                    <h6>4.2. School Responsibilities</h6>
                    <div class="q-pl-md">
                        <p>4.2.1. The School Administrator is responsible for managing the workspace, including
                            assigning user access and permissions.</p>
                        <p>4.2.2. Schools are responsible for ensuring compliance with applicable data
                            protection laws, including:</p>
                        <div class="q-pl-md">
                            <p>4.2.2.1. Informing users about their data rights and obligations.</p>
                            <p>4.2.2.2. Obtaining necessary consent from users.</p>
                            <p>4.2.2.3. Notifying SchoolPeak of required changes to data processing practices.
                            </p>
                        </div>
                        <p>4.2.3. Schools must ensure that any content uploaded or shared via the platform
                            complies with applicable laws and does not infringe on the rights of third parties.
                        </p>
                    </div>
                </div>

                <h5>5. Privacy and Data Protection</h5>
                <div class="q-pl-md">
                    <p>5.1. SchoolPeak processes personal data in accordance with its <a href="#"
                            @click.prevent="$emit('open-privacy')">Privacy Policy</a>.</p>
                    <h6>5.2. Roles and Responsibilities:</h6>
                    <div class="q-pl-md">
                        <p>5.2.1. The School acts as a Data Controller under the GDPR for data provided to
                            SchoolPeak.</p>
                        <p>5.2.2. SchoolPeak acts as a Data Processor for the data provided by the School and a
                            Data Controller for additional user-provided information.</p>
                    </div>
                    <p>5.3. Users should refer to the <a href="#" @click.prevent="$emit('open-privacy')">Privacy
                            Policy</a> for details on data portability, deletion,
                        and restrictions.</p>
                    <p>5.4. SchoolPeak disclaims responsibility for any content generated by users, including
                        comments, absence requests, or uploaded documents.</p>
                </div>

                <h5>6. Intellectual Property</h5>
                <div class="q-pl-md">
                    <p>6.1. Ownership: All intellectual property, including software, designs, and methodologies
                        created by SchoolPeak, remains the sole property of SchoolPeak.</p>
                    <p>6.2. License: Users and Schools are not granted any rights to modify, copy, distribute,
                        or create derivative works based on SchoolPeak's intellectual property.</p>
                    <p>6.3. Feedback: Feedback or suggestions provided by Users or Schools may be used by
                        SchoolPeak to improve the Services without any obligation to implement them or provide
                        compensation.</p>
                    <h6>6.4. Prohibited Actions: Users and Schools may not:</h6>
                    <div class="q-pl-md">
                        <p>6.4.1. Reverse-engineer, copy, or modify the platform.</p>
                        <p>6.4.2. Use SchoolPeak's intellectual property beyond the scope of the Services.</p>
                    </div>
                </div>

                <h5>7. Liability</h5>
                <div class="q-pl-md">
                    <h6>7.1. School Responsibility</h6>
                    <div class="q-pl-md">
                        <p>7.1.1. The School is solely responsible for moderating user-generated content and
                            resolving disputes or complaints from parents, guardians, or other users.</p>
                        <p>7.1.2. SchoolPeak will provide technical assistance if required but disclaims
                            liability for disputes, damages, or dissatisfaction arising from content or services
                            provided by the School.</p>
                    </div>
                    <h6>7.2. SchoolPeak Responsibility</h6>
                    <div class="q-pl-md">
                        <p>7.2.1. SchoolPeak assumes no liability for user-generated content or damages caused
                            by the use of the platform.</p>
                        <p>7.2.2. SchoolPeak disclaims responsibility for scheduling errors, inaccuracies, or
                            user dissatisfaction with services.</p>
                    </div>
                    <p>7.3. Force Majeure: SchoolPeak is not liable for delays or failures in performance due to
                        causes beyond its reasonable control, including cybersecurity incidents, natural
                        disasters, labor disputes, or acts of government.</p>
                </div>

                <h5>8. Suspension of Access</h5>
                <div class="q-pl-md">
                    <p>8.1. SchoolPeak reserves the right to suspend or terminate a user's access to the
                        platform for:</p>
                    <div class="q-pl-md">
                        <p>8.1.1. Non-compliance with these Terms.</p>
                        <p>8.1.2. Violation of applicable laws.</p>
                    </div>
                    <p>8.2. Accounts that have been inactive for over 18 months may be terminated.</p>
                </div>

                <h5>9. Changes and Updates</h5>
                <div class="q-pl-md">
                    <p>9.1. SchoolPeak reserves the right to modify these Terms, Services, or policies at any
                        time. Continued use of the platform constitutes acceptance of changes.</p>
                </div>

                <h5>10. Governing Law</h5>
                <div class="q-pl-md">
                    <p>10.1. These Terms are governed by the laws of the Republic of Latvia.</p>
                    <p>10.2. Any disputes arising from these Terms or the use of the Services shall be subject
                        to the exclusive jurisdiction of Latvian courts.</p>
                </div>

                <h5>11. Contact</h5>
                <div class="q-pl-md">
                    <p>For any questions or support regarding these Terms, please contact us at
                        info@schoolpeak.org.</p>
                </div>
                <footer>
                    <p class="text-right text-italic">Effective Date: December 9, 2024.</p>
                </footer>
            </q-card-section>
            <q-card-actions vertical align="center">
                <q-btn v-close-popup label="Confirm" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
export default {
  name: 'TermsAndConditionsModal',
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
.terms_and_conditions {
  background-color: #fff;
  padding: 20px;
  color: #333;
  max-width: 100%;
}
</style>
