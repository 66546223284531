<template>
    <q-dialog v-model="isVisible" seamless position="bottom" maximized-width="500px">
        <q-card class="absolute-bottom-left cookie-consent">
            <q-card-section class="q-pa-sm" align="center">
                <div class="text-subtitle1">Cookie Settings</div>
                <div class="text-caption">Select your cookie preferences</div>
            </q-card-section>

            <q-card-section class="q-pa-sm">
                <q-list dense>
                    <q-item dense tag="label">
                        <q-item-section avatar>
                            <q-toggle v-model="preferences.necessary" disabled checked color="primary" dense />
                        </q-item-section>
                        <q-item-section>
                            <q-item-label class="text-subtitle2">Necessary</q-item-label>
                            <q-item-label caption>Required for the website to function</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item dense tag="label">
                        <q-item-section avatar>
                            <q-toggle v-model="preferences.functional" color="primary" dense />
                        </q-item-section>
                        <q-item-section>
                            <q-item-label class="text-subtitle2">Functional</q-item-label>
                            <q-item-label caption>Enhance the user experience</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item dense tag="label">
                        <q-item-section avatar>
                            <q-toggle v-model="preferences.analytics" color="primary" dense />
                        </q-item-section>
                        <q-item-section>
                            <q-item-label class="text-subtitle2">Analytics</q-item-label>
                            <q-item-label caption>Help us improve our website</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </q-card-section>

            <q-card-actions align="right" class="q-pa-sm">
                <q-btn flat dense label="Save" @click="savePreferences" />
                <q-btn unelevated dense color="primary" label="Accept All" @click="acceptAll" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>


<script>
export default {
    name: 'CookieConsent',

    data() {
        return {
            isVisible: true,
            preferences: {
                necessary: true,
                analytics: false,
                functional: false
            }
        }
    },

    created() {
        this.checkExistingConsent()
    },

    methods: {
        setCookie(name, value, days) {
            const expires = new Date()
            expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000)
            document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`
        },

        getCookie(name) {
            const value = `; ${document.cookie}`
            const parts = value.split(`; ${name}=`)
            if (parts.length === 2) {
                return parts.pop().split(';').shift()
            }
            return null
        },

        checkExistingConsent() {
            const savedPreferences = this.getCookie('cookiePreferences')
            if (savedPreferences) {
                this.preferences = JSON.parse(savedPreferences)
                this.isVisible = false
                this.applyPreferences()
            }
        },

        savePreferences() {
            const cookieExpirationInDays = 6 * 30
            this.setCookie(
                'cookiePreferences',
                JSON.stringify(this.preferences),
                cookieExpirationInDays
            )
            this.isVisible = false
            this.applyPreferences()
            this.$emit('preferences-updated', this.preferences)
            this.$q.notify({
                message: 'Cookie preferences saved',
                color: 'positive',
                icon: 'check'
            })
        },

        acceptAll() {
            this.preferences = {
                necessary: true,
                analytics: true,
                functional: true
            }
            this.savePreferences()
        },

        applyPreferences() {
            if (this.preferences.analytics) {
                this.initializeAnalytics()
            }

            if (this.preferences.functional) {
                this.initializefunctional()
            }
        },

        initializeAnalytics() {
            // Initialize analytical cookies & tools here
            this.$gtm.enable(true)
        },

        initializefunctional() {
            // Initialize functional cookies & tools here
        }
    }
}
</script>

<style lang="scss" scoped>
.cookie-consent {
    min-width: 300px;
    max-width: 400px;
    bottom: 10px;
    left: 10px;
}
</style>