export default {
  "findActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effortless Afterschool Management For All"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать в ваш портал бронирования"])},
  "userNameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя или Email"])},
  "navbar.feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратная связь"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
  "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильное имя пользователя или пароль"])},
  "createAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать администратора"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
  "settingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
  "selectLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите язык"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить пароль"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий пароль"])},
  "iCommit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я Подтверждаю"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
  "commitmentConfirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение обязательств"])},
  "commitmentMessagePerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмёте 'Я Подтверждаю,' стоимость занятия будет добавлена в ваш ежемесячный счёт в течение следующих 3 месяцев."])},
  "commitmentMessagePerYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмёте 'Я Подтверждаю,' стоимость занятия будет добавлена в ваш ежегодный счёт."])},
  "commitmentMessageQuarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмёте 'Я Подтверждаю,' стоимость занятия будет разделена на квартальные платежи."])},
  "commitmentMessageBiAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмёте 'Я Подтверждаю,' стоимость занятия будет разделена на полугодовые платежи."])},
  "commitmentMessageTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмёте 'Я Подтверждаю,' полная стоимость занятия будет снята немедленно."])},
  "passwordTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот пароль будет использоваться пользователем для входа в систему. Убедитесь, что он надежен. Администраторы могут устанавливать и изменять пароли, но пользователи могут изменять их после входа в систему. Администраторы не могут просматривать пароли пользователей."])},
  "subRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль пользователя"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
  "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите новый пароль"])},
  "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напоминания"])},
  "selectTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите временные слоты"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно"])},
  "remindersManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление напоминаниями"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить пароль"])},
  "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новые пароли не совпадают."])},
  "publishUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовать пользователя"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить пользователя"])},
  "saveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить черновик"])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения"])},
  "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль успешно обновлен."])},
  "passwordUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обновить пароль."])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежные данные"])},
  "editPaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать платежные данные"])},
  "updatePaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить платежные данные"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Английский"])},
  "noSlotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет доступных слотов"])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немецкий"])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напоминание"])},
  "latvian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Латышский"])},
  "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
  "roleManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ролями"])},
  "allActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все занятия"])},
  "userManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление пользователями"])},
  "locationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление местоположением"])},
  "cohortManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление когортой"])},
  "calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарный вид"])},
  "whatsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что нового"])},
  "extracurricularActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внеклассные мероприятия"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
  "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Навигация"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
  "filterActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтровать занятия"])},
  "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать местоположение"])},
  "selectTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать учителя"])},
  "selectCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать когорту"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "goToDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к деталям"])},
  "monthView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месячный вид"])},
  "weekView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недельный вид"])},
  "dayView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дневной вид"])},
  "activityApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центр утверждения бронирования"])},
  "holidayManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление праздниками"])},
  "noPendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В данный момент нет ожидающих бронирований."])},
  "confirmBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить бронирование"])},
  "confirmBookingMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы хотите забронировать ", _interpolate(_named("activityName")), " для ", _interpolate(_named("childName")), "?"])},
  "confirmBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить бронирование"])},
  "doYouWantToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы хотите забронировать"])},
  "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["для"])},
  "paymentDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Для вашей информации: цена за мероприятие составляет ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), " (", _interpolate(_named("pricingType")), ")"])},
  "paymentDetailsSlotMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цена за сеанс составляет ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), ". Платеж будет добавлен к вашему ежемесячному счету."])},
  "perMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в месяц"])},
  "perYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в год"])},
  "recurringDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторяющееся бронирование"])},
  "recurringDialogMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы хотите забронировать это время на каждую неделю?"])},
  "commitmentMessageSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмете 'Я подтверждаю', стоимость сеанса будет добавлена к вашему ежемесячному счету."])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
  "selectAllChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать всех детей"])},
  "quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ежеквартально"])},
  "biAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["раз в полгода"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["всего"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
  "currentActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие занятия"])},
  "myProfileData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои данные профиля"])},
  "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать профиль"])},
  "upcomingActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предстоящие занятия"])},
  "profileUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль успешно обновлен"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотреть"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
  "resetPasswordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить пароль"])},
  "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите адрес электронной почты"])},
  "sendResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить ссылку для сброса"])},
  "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуется адрес электронной почты"])},
  "resetLinkSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка для сброса отправлена! Проверьте свою почту."])},
  "resetLinkFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отправить ссылку для сброса. Попробуйте еще раз."])},
  "emailAndPasswordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуются email и пароль"])},
  "allFieldsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для создания администратора необходимы все поля"])},
  "slotAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот слот уже забронирован. Пожалуйста, выберите другое доступное время."])},
  "activityBookedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Мероприятие '", _interpolate(_named("activity")), "' успешно забронировано."])},
  "filterByChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр по ребенку"])},
  "bookingConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование подтверждено"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что-то пошло не так. Пожалуйста, попробуйте позже."])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка..."])},
  "secureAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обеспечьте безопасность своего аккаунта"])},
  "chooseNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите новый пароль, чтобы восстановить доступ"])},
  "resetYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить пароль"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить пароль"])},
  "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль обязателен"])},
  "passwordTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен содержать не менее 8 символов"])},
  "passwordNumberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен содержать хотя бы одну цифру"])},
  "passwordTooWeak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль слишком слабый"])},
  "passwordResetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш пароль успешно сброшен"])},
  "passwordResetFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сбросить пароль"])},
  "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к входу"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
  "activityBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Занятие успешно забронировано, ожидает подтверждения."])},
  "confirmCancellationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы хотите отменить бронирование для ", _interpolate(_named("activityName")), " для ", _interpolate(_named("childName")), "?"])},
  "confirmCancellationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить отмену"])},
  "confirmAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить действие"])},
  "confirmActionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите продолжить это действие?"])},
  "bookingCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование отменено."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "notificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки уведомлений"])},
  "sessionCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления об отмененных сеансах"])},
  "sessionCancellationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить уведомления об отмененных сеансах"])},
  "sessionReschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления о перенесенных сеансах"])},
  "sessionRescheduleDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить уведомления о перенесенных сеансах"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версия"])},
  "profileUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обновить профиль"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
  "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Временные интервалы"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники"])},
  "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когорты"])},
  "sessionAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта сессия уже забронирована. Пожалуйста, выберите другую."])},
  "newReminderNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления о напоминаниях"])},
  "newReminderNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получайте уведомления о новых напоминаниях, назначенных вам или когорте вашего ребенка."])},
  "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОК"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выбрать"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "newMessageNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомление о новом сообщении"])},
  "newMessageNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получать уведомление по электронной почте при получении нового сообщения."])},
  "newActivityNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления о новых мероприятиях"])},
  "newActivityNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получайте уведомления о новых мероприятиях, доступных для вашего ребенка."])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строк на странице"])},
  "preferencesSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки успешно сохранены"])},
  "bookingCanceledSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование успешно отменено."])},
  "sessionAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сессия добавлена"])},
  "sessionAddedDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая сессия успешно добавлена."])},
  "sessionUncancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сессия восстановлена"])},
  "sessionUncancelledDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сессия восстановлена и пройдет по плану."])},
  "sessionNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомление о заметках сессии"])},
  "sessionNotesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получайте уведомления, когда к сессии добавляется или обновляется заметка."])},
  "welcomeParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать"])},
  "viewActivitiesOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы видите действия"])},
  "noUpcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет предстоящих сессий"])},
  "nextEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши следующие события"])},
  "openBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши открытые бронирования"])},
  "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ребенок:"])},
  "cancelBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить бронирование"])},
  "confirmCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить отмену"])},
  "cohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коорта"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь"])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Планировщик"])},
  "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties datumu"])},
  "selectReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties iemeslu"])},
  "writeReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ierakstiet savu iemeslu"])},
  "reasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet savu iemeslu šeit..."])},
  "sickLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slimības lapa"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cits"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сегодня"])},
  "selectView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать вид"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неделя"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год"])},
  "activity.startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала"])},
  "activity.endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
  "activity.dateBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата бронирования"])},
  "filterActivities.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр мероприятий"])},
  "filterActivities.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все мероприятия"])},
  "filterActivities.past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прошедшие мероприятия"])},
  "filterActivities.ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие мероприятия"])},
  "filterActivities.future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будущие мероприятия"])},
  "filterActivities.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "filterActivities.selectFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать фильтр"])},
  "searchModal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
  "searchModal.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по имени"])},
  "searchModal.reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
  "searchModal.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улица"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Город"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовый индекс"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
  "activityDetail.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О программе"])},
  "activityDetail.sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сессии"])},
  "activityDetail.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлы"])},
  "activityDetail.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "activityDetail.location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Местоположение"])},
  "activityDetail.teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учителя"])},
  "activityDetail.upcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предстоящие сессии"])},
  "activityDetail.previousSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прошедшие сессии"])},
  "activityDetail.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка деталей активности..."])},
  "sessionStatus.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменено"])},
  "sessionStatus.rescheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перенесено"])},
  "sessionStatus.scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запланировано"])},
  "sessionStatus.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По умолчанию"])},
  "navbar.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
  "navbar.planner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Планировщик"])},
  "navbar.bookedActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирования"])},
  "navbar.calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь"])},
  "allChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все дети"])},
  "selectChild_ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать ребенка"])},
  "noActivitiesForChild": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["В настоящее время для ", _interpolate(_named("childName")), " нет доступных мероприятий. Пожалуйста, проверьте позже."])},
  "navbar.reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напоминания"])},
  "navbar.notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
  "navbar.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "reminders.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои напоминания"])},
  "reminders.excludeOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключить старые напоминания"])},
  "reminders.table.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
  "reminders.table.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "reminders.table.dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок"])},
  "reminders.fetchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить напоминания"])},
  "reminders.untitled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без названия"])},
  "reminders.noDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание отсутствует"])},
  "reminders.invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недействительная дата"])},
  "bulkDelete.confirmationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы уверены, что хотите удалить ", _interpolate(_named("count")), " пользователей?"])},
  "bulkDelete.cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
  "bulkDelete.deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
  "reasonForExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина отсутствия"])},
  "excuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить об отсутствии"])},
  "excused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствует"])},
  "excuseForDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Сообщить об отсутствии ", _interpolate(_named("name")), " на день"])},
  "confirmExcuseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить отсутствие"])},
  "confirmExcuseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите сообщить об отсутствии этого ученика на выбранный день?"])},
  "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствие успешно зарегистрировано на выбранный день."])},
  "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось зарегистрировать отсутствие. Пожалуйста, попробуйте еще раз."])},
  "warningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, укажите как дату, так и вескую причину."])},
  "activityDetail.excuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить об отсутствии"])},
  "activityDetail.revertExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить отсутствие"])},
  "activityDetail.excuseSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить об отсутствии на сессии"])},
  "activityDetail.enterReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите причину"])},
  "activityDetail.writeReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напишите вашу причину"])},
  "activityDetail.reasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите вашу причину здесь..."])},
  "activityDetail.reasonSickLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больничный"])},
  "activityDetail.reasonOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
  "unknownActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестное мероприятие"])},
  "unknownLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестное место"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
  "addToCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в календарь"])},
  "reportAbsence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить об отсутствии"])},
  "revertAbsence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить отсутствие"])},
  "filterOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтровать параметры"])},
  "excuseForRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить об отсутствии"])},
  "selectTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите временной период"])},
  "excuseSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствие успешно зарегистрировано."])},
  "noBookingsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирования не найдены. Исследуйте мероприятия в планировщике и бронируйте сейчас!"])},
  "paymentDetailsUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные об оплате в настоящее время недоступны."])}
}