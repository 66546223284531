export default {
  "findActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effortless Afterschool Management For All"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to your booking portal!"])},
  "navbar.feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
  "userNameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name or Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid username or password"])},
  "createAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Admin"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminders"])},
  "remindersManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminders Management"])},
  "settingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "reminderManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminder Management"])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminders"])},
  "selectLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Language"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
  "passwordTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This password will be used by the user to log in. Ensure it is secure. Admins can set and change passwords, but users can change them after logging in. Admins cannot view user passwords."])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
  "noSlotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No slots available"])},
  "selectTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select time slots"])},
  "recurringDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring Booking"])},
  "recurringDialogMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to book this time slot for every week?"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
  "subRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Role"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
  "saveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Draft"])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
  "profileUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile updated successfully"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
  "resetPasswordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Your Password"])},
  "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address"])},
  "sendResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Reset Link"])},
  "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is required"])},
  "resetLinkSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset link sent! Please check your email."])},
  "resetLinkFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send reset link. Please try again."])},
  "emailAndPasswordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email and password are required"])},
  "allFieldsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All fields are required to create an admin"])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Details"])},
  "publishUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish User"])},
  "secureAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure Your Account"])},
  "chooseNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a new password to regain access"])},
  "resetYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Your Password"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required"])},
  "passwordTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 characters"])},
  "passwordNumberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must include at least one number"])},
  "passwordTooWeak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is too weak"])},
  "passwordResetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been reset successfully"])},
  "passwordResetFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to reset the password"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Login"])},
  "notificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Settings"])},
  "sessionCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled Session Notifications"])},
  "sessionCancellationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable notifications for canceled sessions"])},
  "sessionReschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rescheduled Session Notifications"])},
  "sessionRescheduleDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable notifications for rescheduled sessions"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
  "preferencesSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferences have been successfully saved"])},
  "profileUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update profile"])},
  "editPaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Payment Details"])},
  "updatePaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Payment Details"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Password"])},
  "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New passwords do not match."])},
  "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password updated successfully."])},
  "passwordUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update password."])},
  "sessionAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Added"])},
  "sessionAddedDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new session has been successfully added."])},
  "sessionUncancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Uncancelled"])},
  "sessionUncancelledDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The session has been uncancelled and will proceed as planned."])},
  "sessionNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Notes Notification"])},
  "sessionNotesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive notifications when a note is added or updated for a session."])},
  "sessionAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This session has already been booked. Please choose a different one."])},
  "bookingCanceledSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking has been successfully canceled."])},
  "newMessageNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Message Notification"])},
  "newMessageNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive an email notification when you receive a new message."])},
  "newReminderNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminder Notifications"])},
  "newReminderNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive notifications for new reminders assigned to you or your child's cohort."])},
  "newActivityNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Activity Notifications"])},
  "newActivityNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive notifications for new activities available for your child."])},
  "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
  "latvian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvian"])},
  "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "allActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Activities"])},
  "userManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Management"])},
  "locationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location Management"])},
  "cohortManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohort Management"])},
  "calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar View"])},
  "whatsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's New"])},
  "extracurricularActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extracurricular Activities"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "filterActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Activities"])},
  "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Location"])},
  "selectTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Teacher"])},
  "selectCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Cohort"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "goToDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Details"])},
  "monthView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month View"])},
  "weekView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week View"])},
  "dayView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day View"])},
  "activityApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Approval Center"])},
  "holidayManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holiday Management"])},
  "noPendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pending bookings at the moment."])},
  "confirmBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Booking"])},
  "confirmBookingMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to book ", _interpolate(_named("activityName")), " for ", _interpolate(_named("childName")), "?"])},
  "confirmBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Booking"])},
  "doYouWantToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to book"])},
  "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for"])},
  "selectAllChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all children"])},
  "paymentDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For your information, the price for the activity is ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), " (", _interpolate(_named("pricingType")), ")"])},
  "paymentDetailsSlotMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The price per session is ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), ". The payment is added to your monthly invoice."])},
  "commitmentMessageSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click, 'I Commit,' the cost of the session will be added to your monthly invoice."])},
  "perMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per month"])},
  "perYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per year"])},
  "bookingConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking confirmed"])},
  "confirmSlotBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Slot Booking"])},
  "slotDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please confirm the booking for the following slot: Date: ", _interpolate(_named("date")), ", Start Time: ", _interpolate(_named("startTime")), ", End Time: ", _interpolate(_named("endTime"))])},
  "slotBookingConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot booking confirmed!"])},
  "slotAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This slot has already been booked. Please choose another available time."])},
  "activityBookedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The activity '", _interpolate(_named("activity")), "' has been successfully booked."])},
  "quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quarterly"])},
  "biAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-annual"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "iCommit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Commit"])},
  "myProfileData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile Data"])},
  "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
  "currentActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Activities"])},
  "upcomingActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming Activities"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
  "commitmentConfirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commitment Confirmation"])},
  "commitmentMessagePerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click, 'I Commit,' the cost of the activity will be added to your monthly invoice for the next 3 months."])},
  "commitmentMessagePerYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click, 'I Commit,' the cost of the activity will be added to your yearly invoice."])},
  "commitmentMessageQuarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click, 'I Commit,' the cost of the activity will be divided into quarterly payments."])},
  "commitmentMessageBiAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click, 'I Commit,' the cost of the activity will be divided into bi-annual payments."])},
  "commitmentMessageTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click, 'I Commit,' the full cost of the activity will be charged immediately."])},
  "filterByChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by Child"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Please try again later."])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing..."])},
  "activityBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity booked successfully, waiting for approval."])},
  "confirmCancellationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to cancel the booking for ", _interpolate(_named("activityName")), " for ", _interpolate(_named("childName")), "?"])},
  "confirmCancellationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Cancellation"])},
  "confirmAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Action"])},
  "confirmActionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to proceed with this action?"])},
  "bookingCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking has been canceled."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
  "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Slots"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohorts"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rows per page"])},
  "roleManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role Management"])},
  "welcomeParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "viewActivitiesOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you see the activities of"])},
  "noUpcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No upcoming sessions available"])},
  "nextEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your next events"])},
  "openBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your open bookings"])},
  "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child:"])},
  "cancelBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Booking"])},
  "confirmCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Cancellation"])},
  "cohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohort"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planner"])},
  "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a date"])},
  "selectReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Reason"])},
  "writeReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your reason"])},
  "reasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your reason here..."])},
  "sickLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sick Leave"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "selectView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select View"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "activity.startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
  "activity.endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
  "activity.dateBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Booked"])},
  "filterActivities.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Activities"])},
  "filterActivities.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Activities"])},
  "filterActivities.past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past Activities"])},
  "filterActivities.ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing Activities"])},
  "filterActivities.future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Activities"])},
  "filterActivities.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "filterActivities.selectFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Filter"])},
  "searchModal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "searchModal.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name"])},
  "searchModal.reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "searchModal.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "activityDetail.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "activityDetail.sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
  "activityDetail.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
  "activityDetail.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "activityDetail.location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "activityDetail.teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
  "activityDetail.upcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming Sessions"])},
  "activityDetail.previousSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Sessions"])},
  "activityDetail.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading activity details..."])},
  "sessionStatus.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
  "sessionStatus.rescheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rescheduled"])},
  "sessionStatus.scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled"])},
  "sessionStatus.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
  "navbar.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "navbar.planner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planner"])},
  "navbar.bookedActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookings"])},
  "navbar.calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
  "allChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Children"])},
  "selectChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Child"])},
  "noActivitiesForChild": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Currently, there are no activities available for ", _interpolate(_named("childName")), ". Please check back later for updates."])},
  "navbar.reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminders"])},
  "navbar.messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
  "navbar.notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "navbar.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "reminders.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Reminders"])},
  "reminders.excludeOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude old reminders"])},
  "reminders.table.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "reminders.table.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "reminders.table.dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
  "reminders.fetchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to fetch reminders"])},
  "reminders.untitled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled"])},
  "reminders.noDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No description provided"])},
  "reminders.invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Date"])},
  "bulkDelete.confirmationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete ", _interpolate(_named("count")), " user(s)?"])},
  "bulkDelete.cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "bulkDelete.deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "reasonForExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for absence"])},
  "excuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Absence"])},
  "excused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absent"])},
  "excuseForDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Report ", _interpolate(_named("name")), "'s absence for a day"])},
  "confirmExcuseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Absence"])},
  "confirmExcuseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to report this student's absence for the selected day?"])},
  "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence successfully reported for the selected day."])},
  "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to report the absence. Please try again."])},
  "warningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide both a date and a valid reason."])},
  "activityDetail.excuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Absence"])},
  "activityDetail.revertExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revert Absence"])},
  "activityDetail.excuseSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Absence for Session"])},
  "activityDetail.enterReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Reason"])},
  "activityDetail.writeReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your reason"])},
  "activityDetail.reasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your reason here..."])},
  "activityDetail.reasonSickLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sick Leave"])},
  "activityDetail.reasonOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "unknownActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown Activity"])},
  "unknownLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown Location"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
  "addToCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Calendar"])},
  "reportAbsence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Absence"])},
  "revertAbsence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revert Absence"])},
  "filterOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Options"])},
  "excuseForRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Absence"])},
  "selectTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Time Range"])},
  "excuseSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence reported successfully."])},
  "noBookingsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No bookings found. Explore activities under planner and book now!"])},
  "activityDetail.calendarInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To add this calendar to Google Calendar: Copy the link provided. Then, open Google Calendar, click the '+' icon next to 'Other calendars', select 'From URL', paste the link into the 'URL of calendar' field, and click 'Add calendar'."])},
  "activityDetail.linkCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar link copied to clipboard!"])},
  "activityDetail.copyFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to copy link. Please copy it manually."])},
  "activityDetail.openIcsLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open ICS Link"])},
  "activityDetail.importCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Calendar"])},
  "activityDetail.addToCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Calendar"])},
  "paymentDetailsUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment details are not available at the moment."])}
}