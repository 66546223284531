<template>
    <LoadingSpinner />
    <div id="app">
        <div class="main-content">
            <router-view />
        </div>
        <PageFooter />
        <CookieConsent />
        <UnauthorizedError :visible="unauthorizedErrorVisible" @update:visible="unauthorizedErrorVisible = $event" />
    </div>
</template>

<script>
import CookieConsent from './components/CookieConsent.vue'
import UnauthorizedError from '@/components/UnauthorizedError.vue';
import PageFooter from '@/components/PageFooter.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import eventBus from './eventBus';

export default {
  components: {
    CookieConsent,
    UnauthorizedError,
    PageFooter,
    LoadingSpinner
  },
  data() {
    return {
      unauthorizedErrorVisible: false,
    };
  },
  mounted() {
    eventBus.on('showUnauthorizedError', () => {
      this.unauthorizedErrorVisible = true;
    });
  },
};
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1; 
  padding-bottom: 40px; 
  overflow-y: auto;
}
</style>
