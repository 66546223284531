export default {
  "findActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effortless Afterschool Management For All"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laipni lūdzam jūsu rezervēšanas portālā"])},
  "userNameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotājvārds vai E-pasts"])},
  "navbar.feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsauksmes"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierakstīties"])},
  "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepareizs lietotājvārds vai parole"])},
  "createAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izveidot administratoru"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotājvārds"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pasts"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izveidot"])},
  "settingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iestatījumi"])},
  "selectLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties valodu"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainīt paroli"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pašreizējā parole"])},
  "selectAllChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlasīt visus bērnus"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunā parole"])},
  "iCommit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es Apņemos"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nākamais"])},
  "bookingConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācija apstiprināta"])},
  "myProfileData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mani profila dati"])},
  "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediģēt profilu"])},
  "profileUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profils veiksmīgi atjaunināts"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saglabāt"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediģēt"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skatīt"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aizmirsāt paroli?"])},
  "resetPasswordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atiestatīt paroli"])},
  "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet savu e-pasta adresi"])},
  "sendResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosūtīt atiestatīšanas saiti"])},
  "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepieciešams e-pasts"])},
  "resetLinkSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atiestatīšanas saite nosūtīta! Pārbaudiet savu e-pastu."])},
  "resetLinkFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās nosūtīt atiestatīšanas saiti. Mēģiniet vēlreiz."])},
  "emailAndPasswordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepieciešams e-pasts un parole"])},
  "allFieldsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visi lauki ir nepieciešami administratora izveidei"])},
  "activityBookedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aktivitāte '", _interpolate(_named("activity")), "' ir veiksmīgi rezervēta."])},
  "slotAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šis laiks jau ir rezervēts. Lūdzu, izvēlieties citu pieejamo laiku."])},
  "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgādinājumi"])},
  "secureAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodrošiniet savu kontu"])},
  "chooseNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties jaunu paroli, lai atgūtu piekļuvi"])},
  "resetYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atiestatīt savu paroli"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt paroli"])},
  "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole ir obligāta"])},
  "passwordTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolei jābūt vismaz 8 rakstzīmēm garai"])},
  "passwordNumberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolei jāiekļauj vismaz viens cipars"])},
  "passwordTooWeak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole ir pārāk vāja"])},
  "passwordResetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsu parole ir veiksmīgi atiestatīta"])},
  "passwordResetFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās atiestatīt paroli"])},
  "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgriezties pie pieteikšanās"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iesniegt"])},
  "notificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paziņojumu iestatījumi"])},
  "sessionCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paziņojumi par atceltajām sesijām"])},
  "sessionCancellationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iespējot paziņojumus par atceltajām sesijām"])},
  "sessionReschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paziņojumi par pārplānotajām sesijām"])},
  "sessionRescheduleDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iespējot paziņojumus par pārplānotajām sesijām"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versija"])},
  "profileUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās atjaunināt profilu"])},
  "commitmentConfirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apņemšanās apstiprinājums"])},
  "commitmentMessagePerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja jūs nospiežat 'Es Apņemos,' aktivitātes izmaksas tiks pievienotas jūsu ikmēneša rēķinam nākamos 3 mēnešus."])},
  "commitmentMessagePerYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja jūs nospiežat 'Es Apņemos,' aktivitātes izmaksas tiks pievienotas jūsu ikgadējam rēķinam."])},
  "commitmentMessageQuarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja jūs nospiežat 'Es Apņemos,' aktivitātes izmaksas tiks sadalītas ceturkšņa maksājumos."])},
  "commitmentMessageBiAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja jūs nospiežat 'Es Apņemos,' aktivitātes izmaksas tiks sadalītas divreiz gadā."])},
  "commitmentMessageTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja jūs nospiežat 'Es Apņemos,' pilna aktivitātes maksa tiks iekasēta uzreiz."])},
  "newReminderNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgādinājumu paziņojumi"])},
  "newReminderNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saņemiet paziņojumus par jauniem atgādinājumiem, kas piešķirti jums vai jūsu bērna kohortai."])},
  "remindersManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgādinājumu pārvaldība"])},
  "selectTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties laika intervālus"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieejams"])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgādinājums"])},
  "sessionAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šī sesija jau ir rezervēta. Lūdzu, izvēlieties citu."])},
  "newActivityNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu aktivitāšu paziņojumi"])},
  "newActivityNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saņemiet paziņojumus par jaunām aktivitātēm, kas pieejamas jūsu bērnam."])},
  "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labi"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["izvēlēties"])},
  "bookingCanceledSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācija veiksmīgi atcelta."])},
  "newMessageNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jauna ziņojuma paziņojums"])},
  "newMessageNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saņemt e-pasta paziņojumu, kad saņemat jaunu ziņojumu."])},
  "preferencesSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iestatījumi ir veiksmīgi saglabāti"])},
  "sessionAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesija pievienota"])},
  "sessionAddedDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunā sesija ir veiksmīgi pievienota."])},
  "sessionUncancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesija atcelta"])},
  "sessionUncancelledDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesija ir atcelta un norisināsies kā plānots."])},
  "sessionNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paziņojums par sesijas piezīmēm"])},
  "sessionNotesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saņemiet paziņojumus, kad sesijai tiek pievienota vai atjaunināta piezīme."])},
  "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstipriniet jauno paroli"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atjaunināt paroli"])},
  "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunās paroles nesakrīt."])},
  "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole veiksmīgi atjaunināta."])},
  "passwordUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paroles atjaunināšana neizdevās."])},
  "noSlotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav pieejamu laika intervālu"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienot lietotāju"])},
  "saveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saglabāt melnrakstu"])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saglabāt izmaiņas"])},
  "publishUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicēt lietotāju"])},
  "passwordTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šo paroli lietotājs izmantos, lai pieteiktos. Pārliecinieties, ka tā ir droša. Administratori var iestatīt un mainīt paroles, bet lietotāji tās var mainīt pēc pieteikšanās. Administratori nevar skatīt lietotāju paroles."])},
  "subRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotāja loma"])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājumu informācija"])},
  "editPaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediģēt maksājumu informāciju"])},
  "updatePaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atjaunināt maksājumu informāciju"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angļu"])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vācu"])},
  "latvian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latviešu"])},
  "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krievu"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valoda"])},
  "roleManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lomu pārvaldība"])},
  "allActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visas aktivitātes"])},
  "userManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotāju pārvaldība"])},
  "locationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrašanās vietas pārvaldība"])},
  "cohortManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohortas pārvaldība"])},
  "calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalendāra skats"])},
  "whatsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas jauns"])},
  "extracurricularActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ārpusstundu aktivitātes"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotāji"])},
  "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigācija"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paziņojumi"])},
  "filterActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrēt aktivitātes"])},
  "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties atrašanās vietu"])},
  "selectTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties skolotāju"])},
  "selectCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties kohortu"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piemērot"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atiestatīt"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aizvērt"])},
  "goToDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iet uz detaļām"])},
  "monthView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mēneša skats"])},
  "weekView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedēļas skats"])},
  "dayView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienas skats"])},
  "activityApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervēšanas apstiprināšanas centrs"])},
  "holidayManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brīvdienu pārvaldība"])},
  "noPendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pašlaik nav nevienas neapstiprinātas rezervācijas."])},
  "confirmBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt rezervāciju"])},
  "confirmBookingMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vai vēlaties rezervēt ", _interpolate(_named("activityName")), " priekš ", _interpolate(_named("childName")), "?"])},
  "confirmBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt rezervāciju"])},
  "doYouWantToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai vēlaties rezervēt"])},
  "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priekš"])},
  "paymentDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jūsu informācijai: aktivitātes cena ir ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), " (", _interpolate(_named("pricingType")), ")"])},
  "paymentDetailsSlotMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cena par sesiju ir ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), ". Maksājums tiks pievienots jūsu ikmēneša rēķinam."])},
  "perMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mēnesī"])},
  "recurringDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atkārtota rezervācija"])},
  "recurringDialogMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai vēlaties rezervēt šo laiku katru nedēļu?"])},
  "commitmentMessageSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja jūs noklikšķināsiet uz 'Es apņemos', sesijas izmaksas tiks pievienotas jūsu mēneša rēķinam."])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jā"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nē"])},
  "perYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gadā"])},
  "quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reizi ceturksnī"])},
  "biAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divreiz gadā"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kopā"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt"])},
  "currentActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pašreizējās aktivitātes"])},
  "upcomingActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaidāmās aktivitātes"])},
  "filterByChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrēt pēc bērna"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaut kas nogāja greizi. Lūdzu, mēģiniet vēlreiz vēlāk."])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstrāde..."])},
  "activityBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitāte veiksmīgi rezervēta, gaidot apstiprinājumu."])},
  "confirmCancellationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vai vēlaties atcelt rezervāciju par ", _interpolate(_named("activityName")), " priekš ", _interpolate(_named("childName")), "?"])},
  "confirmCancellationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt atcelšanu"])},
  "confirmAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt darbību"])},
  "confirmActionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai esat pārliecināts, ka vēlaties turpināt ar šo darbību?"])},
  "bookingCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācija tika atcelta."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosaukums"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sākuma datums"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beigu datums"])},
  "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laika sloti"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalībnieki"])},
  "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohortas"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darbības"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rindas vienā lapā"])},
  "welcomeParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laipni lūdzam"])},
  "viewActivitiesOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šeit jūs redzat aktivitātes no"])},
  "noUpcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav pieejamu tuvojošos sesiju"])},
  "nextEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsu nākamie notikumi"])},
  "openBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsu atvērtās rezervācijas"])},
  "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bērns:"])},
  "cancelBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt rezervāciju"])},
  "confirmCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt atcelšanu"])},
  "cohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohorta"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalendārs"])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plānotājs"])},
  "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите дату"])},
  "selectReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите причину"])},
  "writeReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напишите вашу причину"])},
  "reasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите вашу причину здесь..."])},
  "sickLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больничный"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visi"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šodien"])},
  "selectView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlēties skatu"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diena"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedēļa"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mēnesis"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gads"])},
  "activity.startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sākuma datums"])},
  "activity.endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beigu datums"])},
  "activity.dateBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācijas datums"])},
  "filterActivities.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrēt aktivitātes"])},
  "filterActivities.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visas aktivitātes"])},
  "filterActivities.past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iepriekšējās aktivitātes"])},
  "filterActivities.ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pašreizējās aktivitātes"])},
  "filterActivities.future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nākotnes aktivitātes"])},
  "filterActivities.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aizvērt"])},
  "filterActivities.selectFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlēties filtru"])},
  "searchModal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meklēt"])},
  "searchModal.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meklēt pēc vārda"])},
  "searchModal.reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atiestatīt"])},
  "searchModal.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aizvērt"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iela"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilsēta"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasta indekss"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefons"])},
  "activityDetail.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par"])},
  "activityDetail.sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesijas"])},
  "activityDetail.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faili"])},
  "activityDetail.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datums"])},
  "activityDetail.location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrašanās vieta"])},
  "activityDetail.teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skolotāji"])},
  "activityDetail.upcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaidāmās sesijas"])},
  "activityDetail.previousSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iepriekšējās sesijas"])},
  "activityDetail.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiek aktivitātes detaļu ielāde..."])},
  "sessionStatus.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelts"])},
  "sessionStatus.rescheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārcelts"])},
  "sessionStatus.scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieplānots"])},
  "sessionStatus.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noklusējums"])},
  "navbar.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sākums"])},
  "navbar.planner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plānotājs"])},
  "navbar.bookedActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācijas"])},
  "allChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visi bērni"])},
  "selectChild_lv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlēties bērnu"])},
  "noActivitiesForChild": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Šobrīd ", _interpolate(_named("childName")), " nav pieejamas nekādas aktivitātes. Lūdzu, pārbaudiet vēlāk."])},
  "navbar.calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalendāra"])},
  "navbar.reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgādinājumi"])},
  "navbar.notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paziņojumi"])},
  "navbar.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aizvērt"])},
  "reminders.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mani atgādinājumi"])},
  "reminders.excludeOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izslēgt vecos atgādinājumus"])},
  "reminders.table.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosaukums"])},
  "reminders.table.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apraksts"])},
  "reminders.table.dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izpildes termiņš"])},
  "reminders.fetchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās iegūt atgādinājumus"])},
  "reminders.untitled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bez nosaukuma"])},
  "reminders.noDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apraksts nav pieejams"])},
  "reminders.invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederīgs datums"])},
  "bulkDelete.confirmationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vai tiešām vēlaties dzēst ", _interpolate(_named("count")), " lietotājus?"])},
  "bulkDelete.cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt"])},
  "bulkDelete.deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzēst"])},
  "reasonForExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neierašanās iemesls"])},
  "excuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņot par neierašanos"])},
  "excused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav klāt"])},
  "excuseForDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ziņot par ", _interpolate(_named("name")), " neierašanos uz vienu dienu"])},
  "confirmExcuseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt neierašanos"])},
  "confirmExcuseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai esat pārliecināts, ka vēlaties ziņot par šī skolēna neierašanos izvēlētajā dienā?"])},
  "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neierašanās veiksmīgi reģistrēta izvēlētajai dienai."])},
  "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās reģistrēt neierašanos. Lūdzu, mēģiniet vēlreiz."])},
  "warningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lūdzu, norādiet gan datumu, gan derīgu iemeslu."])},
  "activityDetail.excuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņot par neierašanos"])},
  "activityDetail.revertExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt neierašanos"])},
  "activityDetail.excuseSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņot par neierašanos sesijā"])},
  "activityDetail.enterReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet iemeslu"])},
  "activityDetail.writeReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzrakstiet savu iemeslu"])},
  "activityDetail.reasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet savu iemeslu šeit..."])},
  "activityDetail.reasonSickLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slimības lapa"])},
  "activityDetail.reasonOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cits"])},
  "unknownActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezināma aktivitāte"])},
  "unknownLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezināma vieta"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte"])},
  "addToCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienot kalendāram"])},
  "reportAbsence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņot par neierašanos"])},
  "revertAbsence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt neierašanos"])},
  "filterOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опции фильтра"])},
  "excuseFailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās reģistrēt prombūtni."])},
  "excuseForRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņot par prombūtni"])},
  "selectTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties laika periodu"])},
  "excuseSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prombūtne veiksmīgi reģistrēta"])},
  "noBookingsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav atrastas rezervācijas. Izpētiet aktivitātes plānotājā un rezervējiet jau tagad!"])},
  "paymentDetailsUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājumu informācija pašlaik nav pieejama."])}
}