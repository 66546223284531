// src/store/index.js
import { createStore } from 'vuex';
import axios from 'axios';
import loading from './loading';

export default createStore({
  modules: {
    loading,
  },
  state: {
    selectedActivities: [],
    selectedChild: '',
    selectedFilter: 'all',
    user: null, // State to hold user data
  },
  mutations: {
    setSelectedActivities(state, activities) {
      state.selectedActivities = activities;
    },
    setSelectedChild(state, child) {
      state.selectedChild = child;
    },
    setSelectedFilter(state, filter) {
      state.selectedFilter = filter;
    },
    setUser(state, user) {
      state.user = user; // Mutation to update the user data
    },
  },
  actions: {
    updateSelectedActivities({ commit }, activities) {
      commit('setSelectedActivities', activities);
    },
    updateSelectedChild({ commit }, child) {
      commit('setSelectedChild', child);
    },
    updateSelectedFilter({ commit }, filter) {
      commit('setSelectedFilter', filter);
    },
    async fetchUser({ commit }, userId) {
      try {
        const token = localStorage.getItem('user-token'); // Fetch token from local storage
        if (!token) {
          throw new Error('User not authenticated');
        }

        const response = await axios.get(`/api/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token.trim()}`, // Set authorization header
          },
        });

        const user = response.data;
        commit('setUser', user); // Commit the user data to the store
      } catch (error) {
        console.error('Error fetching user:', error);
        throw error; // Optionally handle the error further or rethrow it
      }
    },
    updateUser({ commit }, user) {
      commit('setUser', user); // Commit user updates directly
    },
  },
  getters: {
    selectedActivities: (state) => state.selectedActivities,
    selectedChild: (state) => state.selectedChild,
    selectedFilter: (state) => state.selectedFilter,
    user: (state) => state.user, // Getter for the entire user object
    userId: (state) => state.user?.userId || '', // Getter to access user ID safely
  },
});
