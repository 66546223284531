<template>
    <q-dialog v-model="isOpen">
        <q-card class="cookie_policy">
            <q-card-section>
                <h4>Cookies Policy</h4>

                <h5>1. Introduction</h5>
                <p>This Cookies Policy explains how SchoolPeak uses cookies and similar technologies to enhance your
                    experience on our platform. By using SchoolPeak, you consent to the use of cookies as described in
                    this Policy. For details on personal data processing, please refer to our <a href="#"
                        @click.prevent="$emit('open-privacy')">Privacy Policy</a>. For terms
                    governing the use of the platform, see our <a href="#" @click.prevent="$emit('open-terms')">Terms &
                        Conditions</a>.</p>

                <h5>2. What Are Cookies</h5>
                <p>Cookies are small text files stored on your device when you access the SchoolPeak platform. They help
                    us provide essential functionality, improve performance, and collect analytics data.</p>

                <h5>3. Types of Cookies</h5>
                <p>SchoolPeak uses the following types of cookies:</p>
                <div class="q-pl-md">
                    <p>3.1. Essential Cookies: Required for the platform to function properly. These include technical
                        cookies to ensure secure login sessions, navigation, and other functionalities critical to the
                        backend operation of the SchoolPeak portal.</p>

                    <p>3.2. Functional Cookies: Enhance the user experience by remembering preferences and settings.</p>

                    <p>3.3. Analytics Cookies: Collect information about how users interact with the platform to improve
                        performance. Specifically, SchoolPeak uses standard Google Analytics cookies to track usage
                        metrics such as page visits, session duration, and user navigation patterns.</p>
                </div>

                <h5>4. Cookie Usage</h5>
                <p>Cookies are used to enable core functionality and secure login sessions, as well as to analyze user
                    behavior and optimize platform performance. The data collected by cookies may include:</p>
                <div class="q-pl-md">
                    <p>4.1. IP addresses</p>
                    <p>4.2. Device types</p>
                    <p>4.3. Browser types</p>
                    <p>4.4. User actions within the portal</p>
                </div>

                <h5>5. Managing Cookies</h5>
                <p>Users can manage cookie preferences through their browser settings. However, disabling certain
                    cookies, particularly essential ones, may limit the functionality of the platform. For details about
                    data collected via cookies and its usage, refer to our <a href="#"
                        @click.prevent="$emit('open-privacy')">Privacy Policy</a>.</p>

                <h5>6. Contact Information</h5>
                <p>For questions about this Cookies Policy, please contact us at info@schoolpeak.org.</p>

                <footer>
                    <p class="text-right text-italic">Effective Date: December 9, 2024</p>
                </footer>
            </q-card-section>
            <q-card-actions vertical align="center">
                <q-btn v-close-popup label="Confirm" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
export default {
  name: 'CookiePolicyModal',
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
.cookie_policy {
  background-color: #fff;
  padding: 20px;
  color: #333;
  max-width: 100%;
}
</style>
