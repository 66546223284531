<template>
    <q-dialog v-model="isVisible" persistent>
      <q-card class="fixed-width-card">
        <q-card-section>
          <div class="text-h6">Permission Denied</div>
        </q-card-section>
        <q-card-section>
          <p>You do not have the necessary permissions to perform this action. Please contact the administrator for access.</p>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Close" color="primary" @click="closeDialog" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </template>
  
  <script>
  export default {
    name: 'UnauthorizedError',
    props: {
      visible: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      isVisible: {
        get() {
          return this.visible;
        },
        set(value) {
          this.$emit('update:visible', value);
        }
      }
    },
    methods: {
      closeDialog() {
        this.isVisible = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .fixed-width-card {
    width: 600px;
    max-width: 100%;
  }
  </style>
  