<template>
    <q-dialog v-model="isOpen">
        <q-card class="privacy_policy">
            <q-card-section>
                <h4>Privacy Policy</h4>
                <h5>1. Introduction and Overview</h5>
                <p>This Privacy Policy explains how SIA Peak MGMT ("SchoolPeak," "we," "our," or "us") processes your
                    personal data in compliance with the General Data Protection Regulation (GDPR) and other applicable
                    laws. This Policy applies to all users of the SchoolPeak platform ("Users") and describes how
                    personal data is collected, processed, and protected. By using SchoolPeak's Services, you
                    acknowledge that your data will be processed in accordance with this Policy. For further details
                    about the platform's usage, please refer to our <a href="#"
                        @click.prevent="$emit('open-terms')">Terms & Conditions</a>. For information on cookies, see our
                    <a href="#" @click.prevent="$emit('open-cookies')">Cookies Policy</a>.</p>

                <h5>2. Data Controller and Processor Roles</h5>
                <p>SchoolPeak acts as both a Data Processor and Data Controller. The School acts as the Data Controller
                    under GDPR for data provided to SchoolPeak, including information about students, parents,
                    guardians, and staff members. SchoolPeak acts as the Data Processor on behalf of the School for data
                    provided by the School and as the Data Controller for additional user-provided information, such as
                    data entered directly by Users into the platform.</p>

                <h5>3. Categories of Processed Data</h5>
                <p>The categories of data processed include user details provided by the School (e.g., names, email
                    addresses, phone numbers, and addresses), relationships between children and their parents or
                    guardians, and information related to after-school activities such as attendance records and
                    scheduling details. SchoolPeak also processes information submitted by Users directly, such as
                    absence requests, comments, or health-related details when provided. Additionally, data such as IP
                    addresses, geolocation, and device details may be collected for security and analytics purposes, as
                    well as data logged via cookies. For details about the cookies used, refer to the <a href="#"
                        @click.prevent="$emit('open-cookies')">Cookies Policy</a>.
                </p>

                <h5>4. Data Processing and Sharing</h5>
                <p>SchoolPeak processes this data to provide and improve platform functionality, fulfill contractual
                    obligations, ensure compliance with legal requirements, and support account management and technical
                    assistance. Personal data may be shared with subprocessors for hosting, analytics, and technical
                    support services. A list of subprocessors is available upon request. SchoolPeak does not sell or
                    share data with third parties for marketing purposes.</p>

                <h5>5. User Rights Under GDPR</h5>
                <p>Users have rights under GDPR, including the right to access, rectify, delete, or restrict the
                    processing of their data, as well as the right to data portability. Requests for data portability,
                    deletion, or restrictions should be directed to the School, which will coordinate with SchoolPeak to
                    process such requests. Termination of accounts must be initiated by contacting the School, which
                    will notify SchoolPeak to process the request. Termination may result in the loss of access to
                    certain services.</p>

                <h5>6. Data Retention and Security</h5>
                <p>SchoolPeak retains personal data as long as necessary to provide the Services or comply with legal
                    obligations. Backup copies of data may be retained for security purposes for up to 24 months after
                    deletion from active systems. Technical and organizational measures are in place to protect personal
                    data against unauthorized access, alteration, disclosure, or destruction. In the event of a data
                    breach, SchoolPeak will notify the School within 48 hours and assist in complying with GDPR
                    notification requirements.</p>

                <h5>7. Contact Information</h5>
                <p>For questions about this Privacy Policy, please contact us at info@schoolpeak.org.</p>

                <footer>
                    <p class="text-right text-italic">Effective Date: December 9, 2024</p>
                </footer>
            </q-card-section>
            <q-card-actions vertical align="center">
                <q-btn v-close-popup label="Confirm" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
export default {
  name: 'PrivacyPolicyModal',
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
.privacy_policy {
  background-color: #fff;
  padding: 20px;
  color: #333;
  max-width: 100%;
}
</style>
