// src/quasar-user-options.js
import '@quasar/extras/material-icons/material-icons.css';
import 'quasar/dist/quasar.css';
import Notify from 'quasar/src/plugins/notify/Notify.js';import Dialog from 'quasar/src/plugins/dialog/Dialog.js';; 

export default {
  config: {},
  plugins: {
    Notify,
    Dialog, 
  },
};
